body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 200;  /* Set global font weight to 200 */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.grid-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  color: black;
}

.content-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 50px 60px;
}

.page-title {
  font-size: 50px;
  font-weight: 200;  /* Ensure this remains 200 if needed */
}

.page-header {
  font-size: 35px;
  font-weight: 200;  /* Ensure this remains 200 if needed */
}

/* SignaturePad.css */
.signature-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.sigCanvas {
  width: 100%;
  height: 200px;
  border: 1px solid #000; /* Optional: Add a border to the canvas */
}

button {
  margin-top: 10px;
}

/* Layout.css */
.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

footer {
  text-align: center;
  padding: 1em 0;
  background-color: #f1f1f1;
}