.main {
  width: 100%;
  height: 100vh;
  position: relative;
}

#video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute; /* Changed from relative to absolute */
  top: 0;
  left: 0;
  z-index: 1;
}

.content {
  position: absolute;
  display: flex;
  width: 100%;
  height: 80%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  z-index: 10; /* Ensure content is above the video */
  font-size: 40px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2; /* Ensure overlay is above both video and content */
}
